import * as React from 'react';

import Layout from '../components/Layout';

const IndexPage: React.FC = () => {
  return (
    <Layout>
      <div className="test">Hello</div>
    </Layout>
  );
};

export default IndexPage;
